.App{
    margin-left: 250px;
}
:root{
    --main-color: rgb(14, 41, 84);     
    --secondary-color: rgb(31, 110, 140);
    --primary-color: rgb(46, 138, 153);      
    --accent-color: rgb(132, 167, 161);         
    --text-color:rgb(242, 244, 244);           

}