
.bill-parent{
    display: grid;
grid-template-columns: repeat(7, 1fr);
grid-template-rows: repeat(6, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
height: 100vh;
}
.bill-child-1{
    grid-area: 1 / 1 / 3 / 8;
    background-color: var(--secondary-color);
}
.bill-child-2{
    grid-area: 3 / 6 / 7 / 8;
    background-color: var(--secondary-color);
}
.bill-child-3{
    grid-area: 3 / 1 / 7 / 6;
    background-color: rgb(255, 255, 255);
}
.bill-items-view{
    height: 89%;
    overflow-y: scroll;
}


.sale-h1{
    font-size: 30px;
    font-weight: 600;
    color: var(--text-color);
    text-align: center;
}
.sale-input-div-container-1{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin-top: 30px;
}
.sale-input-div{
    width: 340px;
    display: flex;
    justify-content: space-between;
    
}

.sale-input-item-name-label-div{
    display: flex;

}

.sale-input-div-item{
    width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px
    
}
.sale-input-item-result-div{
    background-color: aliceblue;
    z-index: 1;
    width: 250px;
    margin-left: 100px;
    max-height: 100px;
    overflow-y: scroll;
    border-radius: 10px;
    
}
.sale-input-div-container-2{
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    /* width: 831px; */
    margin-top: 20px;
}
.sale-input-label{
    font-size: 20px;
    font-weight: 500;
    color: var(--text-color);
    width: 100px;
    
}
.sale-input{
    width: 250px;
    height: 30px;
    border-radius: 5px;
}

.sale-bill-add-btn{
    background-color:var(--main-color);
    color: var(--text-color);
  align-items: center;
  
  border-radius: 12px;
  box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex: 1 1 auto;
  font-family: Inter,sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  margin: 1px 70px;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .1s,-webkit-box-shadow .1s;
  letter-spacing: 1px;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  max-height: 3rem;

}

.sale-bill-add-btn:hover {
  box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
}













.sale-bill-div{
    margin-top: 50px
}
.sale-bill-enter-btn{
    position: fixed;
    bottom: 20px;
    right: 30px;
    /* border: 1px solid black; */
    padding: 10px 25px;
    border-radius: 10px;
    background-color:var(--main-color);
    color: var(--text-color);
    font-size: 20px;
    font-weight: 500;
}

.sale-bill-total-sub-div{
    display: flex;
    justify-content: space-between;
    margin: 50px 10px;
}
.discount-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
}
.sale-bill-discount-input{
    width: 50px;
    text-align: center;
}
.discount-p{
    color: var(--text-color);
    font-size: 25px;
}

.sale-line{
    border-bottom: 2px solid var(--text-color);
    margin: 20px 5px;
    color: var(--text-color);
}
.sale-bill-label{
    font-size: 22px;
    font-weight: 500;
    color: var(--text-color);
}
.sale-bill-values{
    font-size: 20px;
    color: var(--text-color);
}
.sale-bill-payment-method-div{
    display: flex;
    height: 50px;
    justify-content: space-between;
    width: 90px;
}

.sale-bill-img-oncash, .sale-bill-img-oncredite{
    width: 40px ;
    height: 40px;
    background-color: rgba(240, 248, 255, 0.338);
    padding:5px;
    border-radius: 5px;
}
.sale-bill-img-oncash-active, .sale-bill-img-oncredite-active{
    width: 40px ;
    height: 40px;
    background-color: rgba(240, 248, 255, 0.75);
    padding:5px;
    border-radius: 5px;
}












.bill-head-div{
    display: flex;
    justify-content: space-around;
    height: 40px;
    align-items: center;
    margin-bottom: 10px;
}


.bill-item{
    width: 250px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.bill-qty{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.bill-price{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.bill-total{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.bill-option{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}



.bill-head-div-values{
    display: flex;
    justify-content: space-around;
    margin: 2px;

    background-color: aliceblue;
    height: 40px;
    align-items: center;
    -webkit-box-shadow: 1px 3px 13px -7px rgba(0,0,0,1);
-moz-box-shadow: 1px 3px 13px -7px rgba(0,0,0,1);
box-shadow: 1px 3px 13px -7px rgba(0,0,0,1);
}


.bill-item-values{
    background-color: aliceblue;
    width: 250px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.bill-qty-values{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.bill-price-values{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.bill-total-values{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.bill-option-values{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.bill-delete-img{
    height: 30px;
    width: 30px;
    margin-left: 30px;
}