
.stock-parent{
    display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(2, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
height: 100vh;
}
.stock-child-1{
    grid-area: 1 / 1 / 2 / 2;
    background-color: var(--primary-color);
}
.stock-child-2{
    grid-area: 2 / 1 / 3 / 2;
}


.stock-h1{
    font-size: 30px;
    font-weight: 700;
    color: var(--text-color) ;
    text-align: center;
}
.stock-add-div{
    margin: 10px 20px ;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;

}
.stock-form-div-main{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 10px 10px;
    padding: 20px 60px;
    
    
}
.stock-form-div{
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 1px;
}
.stock-form-label{
    font-size: 18px;
    margin: 2px 5px;
    color: var(--text-color);
    font-weight: 500;
}
.stock-form-input{
    width: 320px;
    height:40px;
    border: 1px solid white;
    border-radius: 5px;
}

.stock-add-button-div{
    position:relative;

    display: flex;
    justify-content: end;
    margin: 20px;
    
}
.stock-add-button{
    cursor: pointer;
    outline: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 10px 24px;
    font-size: 20px;
    border-radius: 10px;
    transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    color: var(--text-color);
    background-color: var(--main-color);
    border-color:white;

}
.stock-add-button:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
.stock-update-button{
    cursor: pointer;
    outline: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 10px 24px;
    font-size: 20px;
    border-radius: 10px;
    transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    color: var(--text-color);
    background-color: var(--main-color);
    border-color:white;

}
.stock-update-button:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
















.stock-view-div-container{
    height: 85%;
    overflow-y: scroll;
}
.stock-view-div{
    margin: 5px;
    background-color: #fff;
    width: 99%;
    height: 97%;
}
.stock-view-div-header{
    display: flex;
    justify-content: space-around;
    height: 40px;
    align-items: center;
    margin-bottom: 10px;
}

.stock-batchno{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-item{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-supplier{
    width: 150px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-qty{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-takingprice{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-sellingprice{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-date{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-action{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-right: 15px;
}



.stock-view-div-value{
    display: flex;
    justify-content: space-around;
    height: 40px;
    align-items: center;
    margin-bottom: 10px;
    background-color: aliceblue;
    -webkit-box-shadow: 1px 3px 13px -7px rgba(0,0,0,1);
-moz-box-shadow: 1px 3px 13px -7px rgba(0,0,0,1);
box-shadow: 1px 3px 13px -7px rgba(0,0,0,1);
}

.stock-batchno-value{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-item-value{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-supplier-value{
    width: 150px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-qty-value{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-takingprice-value{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-sellingprice-value{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-date-value{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.stock-action-value{
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.edite-img{
    width: 30px;
    height: 30px;
    margin-left: 10px;
}
.delete-img{
    width: 30px;
    height: 30px;
    margin-top: 5px;
    margin-right: 10px;
}