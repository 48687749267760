
.supplier-parent{
    display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(2, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
height: 100vh;
}
.supplier-child-1{
    grid-area: 1 / 1 / 2 / 2;
    background-color: var(--primary-color);
}
.supplier-child-2{
    grid-area: 2 / 1 / 3 / 2;
}
.supplier-view-div-value-main{
    height: 87%;
    overflow-y: scroll;
}

.supplier-h1{
    font-size: 30px;
    font-weight: 700;
    color: var(--text-color) ;
    text-align: center;
}
.supplier-add-div{
    margin: 10px 20px ;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;

}
.supplier-form-div-main{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 10px 10px;
    padding: 20px 60px;
    
    
}
.supplier-form-div{
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 1px;
}
.supplier-form-label{
    font-size: 18px;
    margin: 2px 5px;
    color: var(--text-color);
    font-weight: 500;
}
.supplier-form-input{
    width: 320px;
    height:40px;
    border: 1px solid white;
    border-radius: 5px;
}

.supplier-add-button-div{
    position:relative;

    display: flex;
    justify-content: end;
    margin: 20px;
    
}
.supplier-add-button{
    cursor: pointer;
    outline: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 10px 24px;
    font-size: 20px;
    border-radius: 10px;
    transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    color: var(--text-color);
    background-color: var(--main-color);
    border-color:white;

}
.supplier-add-button:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
.supplier-update-button{
    cursor: pointer;
    outline: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 10px 24px;
    font-size: 20px;
    border-radius: 10px;
    transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    color: var(--text-color);
    background-color: var(--main-color);
    border-color:white;

}
.supplier-update-button:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

















.supplier-view-div{
    margin: 5px;
    background-color: #fff;
    width: 99%;
    height: 97%;
}
.supplier-view-div-header{
    display: flex;
    justify-content: space-around;
    height: 40px;
    align-items: center;
    margin-bottom: 10px;
}

.supplier-name{
    width: 150px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.supplier-address{
    width: 250px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.supplier-contact{
    width: 150px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.supplier-email{
    width: 150px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.supplier-nic{
    width: 200px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.supplier-action{
    width: 100px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-right: 15px;
}


.supplier-view-div-value{
    display: flex;
    justify-content: space-around;
    height: 40px;
    align-items: center;
    margin-bottom: 10px;
    background-color: aliceblue;
    -webkit-box-shadow: 1px 3px 13px -7px rgba(0,0,0,1);
-moz-box-shadow: 1px 3px 13px -7px rgba(0,0,0,1);
box-shadow: 1px 3px 13px -7px rgba(0,0,0,1);
}

.supplier-name-value{
    width: 150px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.supplier-address-value{
    width: 250px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}
.supplier-contact-value{
    width: 150px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.supplier-email-value{
    width: 150px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.supplier-nic-value{
    width: 200px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.supplier-action-value{
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.edite-img{
    width: 30px;
    height: 30px;
    margin-left: 10px;
}
.delete-img{
    width: 30px;
    height: 30px;
    margin-top: 5px;
    margin-right: 10px;
}