
.details_card{
    background-color: var(--main-color);
    height: 160px;
    width: 300px;
    margin: 10px 5px;
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    border: 1px solid var(--text-color);
    transition: 0.2s;
}
.details_card:hover{
    background-color:rgba(9, 28, 66, 0.986) ;
}

.details_card_title-div{
    width: 180px;
    /* background-color: antiquewhite; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.details_card_content-div{
    width: 120px;
    /* background-color: violet; */
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.details_card_title{
    text-align: right;
    font-size: 30px;
    font-weight: 600;
    color: var(--text-color);
    
    
}
.details_card_content{
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    color: var(--text-color);
}