.sidebar{
    background-color: var(--main-color);
    width: 250px;
    height: 100vh;
    position: fixed;
}
.sidebar-ccompany-div{
    padding: 20px auto;
}
.sidebar-ccompany{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: var(--text-color);
    padding: 10px ;
}
.sidebar-link-div{
    display: flex;
    flex-direction: column;
}
.sidebar-link-dashboad{
    font-size: 23px;
    font-weight: 500;
    color: var(--text-color);
    border: 1px solid white;
    border-radius: 5px;
    margin: 10px 20px;
    padding: 5px 40px;
    transition: 0.4s;
}
.sidebar-link-dashboad-active{
    font-size: 23px;
    font-weight: 500;
    color: var(--text-color);
    border: 1px solid white;
    border-radius: 5px;
    margin: 10px 20px;
    padding: 5px 40px;
    background-color: rgba(180, 239, 239, 0.479)

    
}

.sidebar-link-dashboad:hover{
    font-size: 23px;
    font-weight: 500;
    color: var(--text-color);
    border: 1px solid white;
    border-radius: 5px;
    margin: 10px 20px;
    padding: 5px 40px;
    /* background-color:var(--accent-color); */
    background-color: rgba(180, 239, 239, 0.137)
}